import React from "react";
import { navigateTo } from "gatsby-link";
import styled from 'styled-components';
import tw from 'tailwind.macro';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const MainWrapper = styled.div`
  ${tw`w-full flex flex-wrap lg:-mx-3 my-12`};
`

const SecondWrapper = styled.div`
  ${tw`w-full lg:w-1/2 px-3`};
  margin: 0 auto;
`

const Label = styled.div`
  ${tw`block uppercase tracking-wide text-grey-darker text-xs font-bold mt-6 mb-2 text-left`};
`

const Form = styled.form`
  ${tw`w-full max-w-4x1`};
`

const Input = styled.input`
  ${tw`appearance-none block w-full bg-grey-light text-grey-dark border border-grey-light rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-grey`}
`

const Text = styled.textarea`
  ${tw`appearance-none block w-full bg-grey-light text-grey-dark border border-grey-light rounded mb-6 px-3 py-2 leading-tight focus:outline-none focus:bg-white focus:border-grey`};
`

const Button = styled.button`
  ${tw`shadow bg-blue hover:bg-blue-lighter focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded mt-4`};
`

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Form
        name="contact"
        method="post"
        action="/gracias"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        data-netlify-recaptcha="true"
        onSubmit={this.handleSubmit}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="subject" value="Estoy interesado en la oficina" />
        <MainWrapper>
          <SecondWrapper>
            <Label
              htmlFor="nombre"
            >
              Nombre*
            </Label>

            <Input
              name="nombre"
              id="first-name"
              type="text"
              placeholder="Tu nombre aquí"
              onChange={this.handleChange}
            />

            <Label
              htmlFor="telefono"
            >
              Teléfono*
            </Label>

            <Input
              name="telefono"
              id="telefono"
              type="tel"
              placeholder="Tu teléfono aquí"
              onChange={this.handleChange}
            />

            <Label
              htmlFor="email"
            >
              Email*
            </Label>

            <Input
              name="email"
              id="email"
              type="email"
              placeholder="Tu email aquí"
              onChange={this.handleChange}
            />

            <Label
              htmlFor="información"
            >
              Cuéntanos de ti y tu negocio*
            </Label>

            <Text
              name="información"
              id="información"
              type="text"
              placeholder="Tu mensaje aquí"
              rows="4"
              onChange={this.handleChange}
            />

            <div data-netlify-recaptcha="true"></div>

            <Button
              aria-label="submit"
              type="submit"
              name="submitbutton"
            >
              Contactanos
            </Button>
          </SecondWrapper>
        </MainWrapper>
      </Form>
    );
  }
}