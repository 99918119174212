import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Lightbox from '../common/Lightbox';

import { Section, Container } from '@components/global';

const Team = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "fotos" } }) {
          edges {
            node {
              childImageSharp {
                original {
                  width
                  height
                }
                fluid {
                  ...GatsbyImageSharpFluid
                  originalName
                  originalImg
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="fotos" accent="secondary">
        <Container style={{ position: 'relative' }}>
          <h1>Galería de Fotos</h1>
          <TeamGrid>
                <div>
                  <Lightbox 
                  columns={width => {
                    if (width < 700) {
                      return 2
                    } else if (width < 1000) {
                      return 5
                    } else {
                      return 5
                    }
                  }}
                  photos={data.allFile.edges}/>
                </div>
          </TeamGrid>
        </Container>
      </Section>
    )}
  />
);

const TeamGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 100%);
  grid-template-rows: min-content;
  grid-gap: 50px;
  justify-content: space-between;
  width: 100%;
  margin-top: 72px;

  @media (max-width: ${props => props.theme.screen.lg}) {
    justify-content: start;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    grid-gap: 24px;
  }
`;

export default Team;
