import React from 'react';
import styled from 'styled-components';

import { Section, Container } from '@components/global';
import GoogleMap from '../common/Map/GoogleMap';

const LogoGrid = styled.div`
  width: 80vw;
  display: flex;
  justify-items: center;
  margin-top: 96px;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  position: relative;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
  }
`;

export default () => (
  <Section id="ubicación" accent>
    <StyledContainer>
      <div>
        <h1>Ubicación</h1>
        <h2>privilegiada en el Norte de Bogotá</h2>
        <LogoGrid>
            <GoogleMap />
        </LogoGrid>
      </div>
    </StyledContainer>
  </Section>
)