import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';

const StyledContainer = styled(Container)`
  ${tw`h-full lg:h-full lg:pb-12`};
`

const StyledDiv = styled.div`
  ${tw`py-12`};
`

const StyledList = styled.ul`
  ${tw`-pt-2`};
`;

const StyledLI = styled.li`
  ${tw`py-0`};
`;

const Indent = styled.p`
  ${tw`ml-12 block`};
  font-size: 20px;
`

const FAQS = [
  {
    title: 'Características detalladas',
    content: () => (
      <>
        <StyledList>
          <StyledLI>Area:&nbsp;&nbsp;250 m2</StyledLI>
          <StyledLI>Open Offices:&nbsp;&nbsp;3</StyledLI>
          <StyledLI>Oficinas:&nbsp;&nbsp;2</StyledLI>
          <StyledLI>Baños:&nbsp;&nbsp;3</StyledLI>
          <StyledLI>Parqueaderos:<Indent>3 internos<br />2 espacios externos con vigilante</Indent></StyledLI>
          <StyledLI>Otros:<Indent>1 espacio de cafetería<br />1 verde jardín 
          con parrilla BBQ</Indent></StyledLI>
        </StyledList>
      </>
    ),
  },
  {
    title: 'Ubicación en detalle',
    content: () => (
      <>
        <StyledList>
          <StyledLI>Ciudad:&nbsp;&nbsp;Bogotá</StyledLI>
          <StyledLI>Zona:&nbsp;&nbsp;Noroccidente</StyledLI>
          <StyledLI>Localidad:&nbsp;&nbsp;Suba</StyledLI>
          <StyledLI>Barrio:&nbsp;&nbsp;Tierra Linda</StyledLI>
          <br />
          <StyledLI>Estrato:&nbsp;&nbsp;4</StyledLI>
        </StyledList>
      </>
    ),
  },
  {
    title: 'Información financiera',
    content: () => (
      <>
        <StyledList>
          <StyledLI>Precio:&nbsp;&nbsp;$ 3,900,000 por mes</StyledLI>
        </StyledList>
      </>
    ),
  },
  {
    title: '¿Cuándo está disponible?',
    content: () => (
      <>
        <p>¡Disponibilidad inmediata! Llama o escríbenos para agendar una cita.</p>
      </>
    ),
  },
];

const Faq = () => (
  <Section id="otros">
    <StyledContainer>
      <h1 style={{ marginBottom: 40 }}>Otros</h1>
      <StyledDiv>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </StyledDiv>
    </StyledContainer>
  </Section>
);

export default Faq;
