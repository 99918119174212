import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Fade from 'react-reveal/Fade';


import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        main_negocios: file(
          sourceInstanceName: { eq: "main" }
          name: { eq: "negocios" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        main_transmilenio: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "transmilenio" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        main_parilla: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "parilla" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        main_oficina: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "oficina1" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="características">
        <Container>
        <h2>Características y descripción</h2>
          <Grid>
            <div>
              <h2>Más que una oficina</h2>
              <p>
              El secreto para que tu empresa crezca es ubicarla en 
              un espacio que refleje su personalidad y valores. 
              Dales la bienvenida a tus clientes en una atractiva 
              recepción, y hazlos seguir a tu oficina con vista 
              a un hermoso jardín.
              </p>
            </div>
            <Art>
              <Fade right>
                <SideImg fluid={data.main_negocios.childImageSharp.fluid} />
              </Fade>
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Fade left>
                <SideImg fluid={data.main_transmilenio.childImageSharp.fluid} />
              </Fade>
            </Art>
            <div>
              <h2>En la ubicación perfecta</h2>
              <p>
              ¡No busques más! Este amplio inmueble de 250 m2 está ubicado
               entre la 127 y las 134, unas cuadras abajo de la autopista 
               norte. En una locación privilegiada de fácil acceso, ambas 
               estaciones de Transmilenio están a 10 minutos y el SITP 
               está a unos pasos. Está rodeado de bancos, negocios, 
               restaurantes, y supermercados, al igual que varios centros 
               religiosos. 
              </p>
            </div>
          </Grid>
          <Grid>
            <div>
              <h2>Características principales</h2>
              <p>
              Cuenta con 3 parqueaderos internos y 2 espacios externos con vigilante. 
              Tiene 2 oficinas, 3 baños, un espacio de cafetería, y un verde jardín 
              con parrilla BBQ perfecta para el team building. 
              <br />
              <br />
              Lo mejor de todo:<br />3 amplios espacios abiertos ideales para<br />un open office o mesas de reuniones. 
              </p>
            </div>
            <Art>
              <Fade right>
                <SideImg fluid={data.main_parilla.childImageSharp.fluid} />
              </Fade>
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Fade left>
                <SideImg fluid={data.main_oficina.childImageSharp.fluid} />
              </Fade>
            </Art>
            <div>
              <h2>Hazlo tuyo</h2>
              <p>
              ¡Este espacio es un lienzo en blanco! Perfecto para una start up,
              una inmobiliaria, una empresa de ventas con atención al cliente 
              directa, un estudio de yoga, baile, o arte. 
              <br /><br />
              ENTRE MUCHAS OTRAS OPCIONES. 
              <br /><br />
              Dale color con la personalidad de tu negocio
              </p>
            </div>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

const SideImg = styled(Img)`
  ${tw`shadow-lg rounded`};
`;

export default About;
