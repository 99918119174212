import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

import { Section, Container } from '@components/global';
import Contact from '../common/Form';

const StyledContainer = styled(Container)`
  display: block;
  position: relative;
  max-width: 1200px;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
  }
`;

const ContactArea = styled.div`
  ${tw`w-screen lg:w-full h-screen lg:h-full lg:pb-24 inline lg:block text-center pt-12`};  
  margin: 0 auto;
`;

export default () => (
  <Section id="contáctanos">
    <StyledContainer>
      <h1>Contáctanos</h1>
      <ContactArea>
          <Contact />
      </ContactArea>
    </StyledContainer>
  </Section>
)