import React from 'react';
import Helmet from 'react-helmet';

const SEO_DATA = {
  description: 'Tu nueva oficina en Bogotá - Amplia y luminosa - 250 m2 - Entre la 127 y las 134',
  title: 'Oficina en Arriendo',
  url: 'https://www.oficinaenarriendo.com',
  author: 'pfarrelliv',
  keywords: ['oficinaenarriendo', 'bogotá', 'nuevaoficina', 'enarriendo', 'arriendo', 'oficina', 'colombia', 'bogota' ],
};

const SEO = () => {
  return (
    <Helmet>
      <meta property="og:title" content={SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta property="og:description" content={SEO_DATA.description} />
      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      <meta name="author" content={SEO_DATA.author} />
      <title>{SEO_DATA.title}</title>
      <html lang="es" />
    </Helmet>
  );
};

export default SEO;
