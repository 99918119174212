import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import SubHeader from '@sections/SubHeader';
import Caracteristicas from '@sections/Caracteristicas';
import Ubicacion from '@sections/Ubicacion';
import Fotos from '@sections/Fotos';
import Otros from '@sections/Otros';
import Contacto from '@sections/Contacto';
import Footer from '@sections/Footer';

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    <SubHeader />
    <Caracteristicas />
    <Ubicacion />
    <Fotos />
    <Otros />
    <Contacto />
    <Footer />
  </Layout>
);

export default IndexPage;
