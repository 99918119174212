import React from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'
import styled from 'styled-components';

const MapDiv = styled.div`
  width: 77%;
  height: 60vh;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
  }
`

const defaultProps = {
  center: {
    lat: 4.7118493,
    lng: -74.0557906,
  },
  zoom: 14,
}

const GoogleMap = () => (
  <MapDiv>
    <GoogleMapReact
      bootstrapURLKeys={{
        key: `${process.env.MAP_API_KEY}`,
      }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <Marker
        lat={4.7118493}
        lng={-74.0557906}
        name="My Marker"
        color="blue"
      />
    </GoogleMapReact>
  </MapDiv>
)

export default GoogleMap
