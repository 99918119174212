import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

const SubHeader = styled.section`
  ${tw`block bg-grey-light`};
  height: 100%;
`;

const SubHeadText = styled.h2`
  ${tw`w-2/3 py-6 lg:py-4 text-center`};
  margin: 0 auto;
`;

const StyledA = styled.a`
  ${tw`text-black font-semibold hover:text-blue no-underline`};
`

export default () => (
    <SubHeader>
        <SubHeadText>
            Amplia y Luminosa Oficina - 250 m2 - Llama Ya:&nbsp;<StyledA href="tel:3115169425">3115169425</StyledA>
        </SubHeadText>
    </SubHeader>
)